import Layout from '@scentregroup/shared/hub-components/layout'
import {
  SGPageTemplateContentCollection,
  SGPageTemplateContentEntry,
  SGPageTemplate,
} from '@scentregroup/shared/types/page-templates'
import React from 'react'

export const useQuickLinksFromTemplate = (
  template: SGPageTemplate
): [
  template: SGPageTemplate,
  quickLinks: React.ComponentProps<typeof Layout>['quickLinks'],
] => {
  return React.useMemo(() => {
    const utilityPanelModule = template.modules.find(
      module =>
        module.__typename === 'SGPageTemplateContentCollection' &&
        module.kind === 'UTILITY_PANEL'
    ) as unknown as SGPageTemplateContentCollection
    const templateWithoutQuickLinks = {
      ...template,
      modules: template.modules.filter(module => module !== utilityPanelModule),
    }
    const quickLinks = utilityPanelModule?.items
      .filter((item): item is SGPageTemplateContentEntry => true)
      .map(item => ({
        label: item.title,
        url: item.callToAction.url,
        image: item.image.url,
      }))
    return [templateWithoutQuickLinks, quickLinks]
  }, [template])
}
